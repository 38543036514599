<template>
  <tr>
    <td :class="setclass()">{{ symbol }}</td>
    <td :class="setclass()">{{ description }}</td>
    <td :class="setclass()" style="text-align: right">{{ sum }}</td>
    <td :class="setclass()" style="text-align: right">{{ priceNow }}</td>
    <td :class="setclass()" style="text-align: right">{{ valueNow }}</td>
    <td :class="setclass()" style="text-align: right">{{ sumMoney }}</td>
    <td :class="setclass()" style="text-align: right">{{ profit }}</td>
  </tr>
</template>

<script setup>
//VUE core
import { defineProps } from 'vue';

const props = defineProps([
  'symbol',
  'description',
  'sum',
  'sumMoney',
  'valueNow',
  'profit',
  'priceNow',
]);

function setclass() {
  if (props.profit > 0) return 'bg-profit';
  return 'bg-profit-minus';
}
</script>

<style>
.bg-profit {
  color: #2ebe50 !important;
}
.bg-profit-minus {
  color: #e03d58 !important;
}
</style>
