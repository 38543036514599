<template>
  <tr>
    <td>{{ symbol }}</td>
    <td>{{ description }}</td>
    <td>{{ getFormatedDate }}</td>
    <td style="text-align: right">{{ grossAmount }}</td>
    <td style="text-align: right">{{ netAmount }}</td>
    <td style="text-align: right">{{ tax }}</td>
    <td style="text-align: right">{{ currency }}</td>
    <!-- <td :class="setclass()" style="text-align: right">{{ sum }}</td>
      <td :class="setclass()" style="text-align: right">{{ priceNow }}</td>
      <td :class="setclass()" style="text-align: right">{{ valueNow }}</td>
      <td :class="setclass()" style="text-align: right">{{ sumMoney }}</td>
      <td :class="setclass()" style="text-align: right">{{ profit }}</td> -->
  </tr>
</template>

<script setup>
//VUE core
import { defineProps, computed } from 'vue';
import appUtils from '../utils/appUtils';

//const props = defineProps(['symbol', 'description']);
const props = defineProps([
  'symbol',
  'description',
  'currency',
  'payDateFormated',
  'grossAmount',
  'netAmount',
  'tax',
]);

const getFormatedDate = computed(function () {
  return appUtils.getFormatedDate(props.payDateFormated);
});
</script>
